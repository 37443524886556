import { Amplify, Auth, PubSub } from 'aws-amplify';
import moment from 'moment';
import { PoolData } from '../../../actions/apicall/getAgencyConfigure';
import { AWSIoTProvider } from '@aws-amplify/pubsub';

const GRAPHQL_URL =
  process.env.REACT_APP_API_ENV === 'development'
    ? 'https://yu432oihcvgc5p6numhvx25p3e.appsync-api.ap-northeast-1.amazonaws.com/graphql'
    : 'https://6qp3bnf4ejcqrdbejkcuamyeey.appsync-api.ap-northeast-1.amazonaws.com/graphql';

const API_KEY =
  process.env.REACT_APP_API_ENV === 'development'
    ? 'da2-pmxp36sgfvdrtcebqisxtycjma'
    : 'da2-q3dqbald5nanvboqsnemylgksu';

/**
 * @description ログイン画面に戻る際のURLを取得
 * @param {string} lang 言語(jp,en)
 * @returns ログイン画面のURL
 */
function getLoginUrl(lang: string) {
  let logOutUrl = '/login/';
  if (lang === 'en') {
    logOutUrl = '/login/login_en.html';
  }
  const agency = window.localStorage.getItem('agency');
  const agencyNameParam = agency ? `?agency=${agency}` : '?agency=';

  return logOutUrl + agencyNameParam;
}

export async function getCognitoClient(l: any) {
  let lang = l;
  if (!lang) {
    lang = (window as any).loginUser.language;
  }

  const agency = window.localStorage.getItem('agency');
  const agencyName = agency ? agency : 'test';
  const env = process.env.REACT_APP_API_ENV === 'development' ? 'development' : 'production';

  const poolData: PoolData = JSON.parse(String(window.localStorage.getItem('p')));
  window.loginUser = {
    ...window.loginUser,
    has_infraView: !!poolData.iot_endpoint_url ? true : false,
  };

  if (!Amplify.PubSub || Amplify.PubSub._pluggables.length === 0) {
    // MQTToverWebsocketの設定
    Amplify.register(PubSub);
    Amplify.addPluggable(
      new AWSIoTProvider({
        aws_pubsub_region: poolData.region,
        aws_pubsub_endpoint: poolData.iot_endpoint_url,
      }),
    );
  }

  Amplify.configure({
    Auth: {
      identityPoolId: poolData.identityPoolId,
      region: poolData.region,
      userPoolId: poolData.userPoolId,
      userPoolWebClientId: poolData.clientId,
    },
    API: {
      aws_appsync_graphqlEndpoint: GRAPHQL_URL,
      aws_appsync_region: poolData.region,
      aws_appsync_authenticationType: 'AWS_IAM',
      aws_appsync_apiKey: API_KEY,
    },
    Logging: {
      logGroupName: `/dispatcher/${env}`,
      logStreamName: `/${agencyName}/${window.navigator.userAgent}`,
      region: poolData.region,
    },
  });

  const cognitoUser = await Auth.currentAuthenticatedUser().catch((err) => {
    // No authenticated
    console.info(err);
  });

  return cognitoUser;
}

const interval = 10 * 60 * 1000; // 10 minutes
let timer: any;
let prevExecTime: any;

/**
 * @description リフレッシュトークン確認（APIを呼び出すと自動で更新されます）
 */
function reflashClient() {
  console.info(`token reflash start${moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ')}`);
  Auth.currentSession();
  Auth.currentCredentials()
    .then((credentials: any) => {
      console.info('token exp:', credentials.expireTime);
      window.sessionStorage.setItem('c', JSON.stringify(credentials));
      (window as any).sessionStorage.setItem('exp', moment(credentials.expireTime).unix());

      return Auth.currentAuthenticatedUser();
    })
    .then((user) => {
      window.sessionStorage.setItem('at', user.signInUserSession.accessToken.jwtToken);
    })
    .catch(() => {
      // 期限切れ
      console.info('token reflash faild');
    });
}

/**
 * @description トークン更新
 * @param {Object} credentials クレデンシャル情報
 * @param {string} lang 言語
 * @returns 新しいクレデンシャル情報
 */
function updateToken(credentials: any, lang: string) {
  return new Promise((resolve, reject) => {
    const nowDate = moment();
    let newCredentialsTemp: any;
    if (!prevExecTime || prevExecTime.diff(nowDate, 'minutes') < 0) {
      prevExecTime = moment();
      Auth.currentSession();
      Auth.currentCredentials()
        .then((newCredentials: any) => {
          window.sessionStorage.setItem('c', JSON.stringify(newCredentials));
          (window as any).sessionStorage.setItem('exp', moment(newCredentials.expireTime).unix());

          newCredentialsTemp = newCredentials;
          return Auth.currentAuthenticatedUser();
        })
        .then((user) => {
          window.sessionStorage.setItem('at', user.signInUserSession.accessToken.jwtToken);

          resolve(newCredentialsTemp);
        })
        .catch(() => {
          // 期限切れ
          console.info('token reflash faild');
          const errorCode = 'error=timeout';
          window.location.href = `${getLoginUrl(lang)}&${errorCode}`;
          reject();
        });
    }
    resolve(credentials);
  });
}

/**
 * @description クレデンシャル情報の生成
 * @param {Object} result cognitoから渡された認証情報
 * @returns 新しいクレデンシャル情報
 */
function createToken(result: any) {
  const poolData = JSON.parse(String(window.localStorage.getItem('p')));
  return {
    accessKey: result.accessKeyId,
    secretKey: result.secretAccessKey,
    sessionToken: result.sessionToken,
    region: poolData.region,
    accessToken: window.sessionStorage.getItem('at'),
  };
}

export function getAPIClient(l: any) {
  let lang = l;
  if (!lang) {
    lang = (window as any).loginUser.language;
  }

  if (!timer) {
    timer = setInterval(reflashClient, interval);
  }

  const credentials = JSON.parse(String(window.sessionStorage.getItem('c')));
  // 前回実行から時間が経過したら再度トークンの認証を行う。
  const nowDate = moment();
  if (!prevExecTime || prevExecTime.diff(nowDate, 'minutes') < 0) {
    prevExecTime = moment();
    Auth.currentSession();
    Auth.currentCredentials()
      .then((newCredentials: any) => {
        window.sessionStorage.setItem('c', JSON.stringify(newCredentials));
        window.sessionStorage.setItem('exp', String(moment(newCredentials.expireTime).unix()));

        return Auth.currentAuthenticatedUser();
      })
      .then((user) => {
        window.sessionStorage.setItem('at', user.signInUserSession.accessToken.jwtToken);
      })
      .catch(() => {
        // 期限切れ
        console.info('token reflash faild');
      });
  }
  const config = {
    accessKey: credentials.data.Credentials.AccessKeyId,
    secretKey: credentials.data.Credentials.SecretKey,
    sessionToken: credentials.data.Credentials.SessionToken,
    region: credentials.cognito.config.region,
    accessToken: window.sessionStorage.getItem('at'),
  };
  if (window.loginUser.env === 'staging') {
    return (window as any).apigClientFactory.newClient(config, 'staging');
  }
  return (window as any).apigClientFactory.newClient(config, process.env.REACT_APP_API_ENV);
}

/**
 * @description API呼び出す用のAWS SDKの取得
 * @param {string} l 言語
 * @returns AWS SDKオブジェクト
 */
export function getAPIClientAsync(l?: string) {
  let lang = l || '';

  if (!lang) {
    lang = (window as any).loginUser.language;
  }

  if (!timer) {
    timer = setInterval(reflashClient, interval);
  }

  const credentials = JSON.parse(String(window.sessionStorage.getItem('c')));
  // 前回実行から時間が経過したら再度トークンの認証を行う。
  return updateToken(credentials, lang).then((result) => {
    const config = createToken(result);
    if (window.loginUser.env === 'staging') {
      return (window as any).apigClientFactory.newClient(config, 'staging');
    }
    return (window as any).apigClientFactory.newClient(config, process.env.REACT_APP_API_ENV);
  });
}

/**
 * @description API呼び出す用のAWS SDKの取得(Twilio用)
 * @param {string} l 言語
 * @returns AWS SDKオブジェクト
 */
export function getTwilioAPIClientAsync(l?: string) {
  let lang = l || '';
  if (!lang) {
    lang = (window as any).loginUser.language;
  }

  if (!timer) {
    timer = setInterval(reflashClient, interval);
  }

  const credentials = JSON.parse(String(window.sessionStorage.getItem('c')));
  // 前回実行から時間が経過したら再度トークンの認証を行う。
  return updateToken(credentials, lang).then((result) => {
    const config = createToken(result);
    return (window as any).apigTwilioClientFactory.newClient(config, process.env.REACT_APP_API_ENV);
  });
}

/**
 * @description ログインチェック
 * @returns true=ログイン中、false=ログイン切れもしくはログインしていない
 */
export function checkLogin() {
  const expireTime = window.sessionStorage.getItem('exp');
  const nowTime = moment().unix();
  if (expireTime && nowTime > parseInt(expireTime)) {
    return false;
  }
  return true;
}
