import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import Header from '../../common/components/header';
import lang from '../../lang';
import {
  Button_Reload,
  Div_Error,
  Div_ErrorBox,
  Div_HeaderRow,
  Div_IconRow,
  Div_LinkRow,
} from './index.styles';

const ErrorFallback = ({ error }: FallbackProps) => {
  console.info('boundary catch error', error.message);
  return (
    <div>
      <Header
        navigate={() => {
          /* none */
        }}
        hasGoBack={false}
        goBack={() => {
          /* none */
        }}
      />
      <Div_Error>
        <Div_ErrorBox>
          <Div_IconRow className="row">
            <div className="col">
              <img src="/static/set/img/common/icon_error_red.svg" alt="error-icon" />
            </div>
          </Div_IconRow>
          <Div_HeaderRow>
            <div className="col">
              <h3>{lang.get('error-boundary.title')}</h3>
              <p>{lang.get('error-boundary.desc')}</p>
            </div>
          </Div_HeaderRow>
          <div className="row">
            <div className="col">
              <Button_Reload
                className="btn btn-primary btn-lg"
                onClick={() => window.location.reload()}
              >
                {lang.get('error-boundary.reload')}
              </Button_Reload>
            </div>
          </div>
          <Div_LinkRow className="row">
            <div className="col">
              <p>
                {lang.get('error-boundary.link.desc1')}
                <a
                  href="https://www.softbank.jp/drive/#contact"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {lang.get('error-boundary.link.desc2')}
                </a>
                {lang.get('error-boundary.link.desc3')}
              </p>
            </div>
          </Div_LinkRow>
        </Div_ErrorBox>
      </Div_Error>
    </div>
  );
};

export default ErrorFallback;
