import 'contents/css/common/style.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { resetNotification } from '../../../actions/notification/notificationAction';
import {
  acceptCall,
  hangupCall,
  ignoreCall,
  rejectCall,
} from '../../../actions/twilio/TwilioAction';
import LiveViewSession from '../../../pages/running-vehicles/LiveViewSession';
import CallDialog from '../callDialog';
import CallDialogPanel from '../callDialogPanel';
import HeaderClock from '../headerClock';
import styleConstants from '../../../contents/css/constants/styleConstants';
import {
  makeNewWhiteLabel,
  updateWhiteLabel,
} from '../../../reducers/view/whitelabel/whitelabelAction';
import { WhiteLabelReducerState } from '../../../reducers/view/whitelabel/WhiteLabelReducer';
import getLoginMediaListGet from '../../../actions/apicall/getLoginMediaList';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const $ = require('jquery');

const scrollMargin = 24;

interface Props {
  id?: number;
  navigate: any;
  autoHide?: boolean;
  hasGoBack: boolean;
  goBack: () => void;
}

type ComponentProps = StateToProps & DispatchToProps & Props;

interface State {
  isHide: boolean;
}
/**
 * @description 共通画面ヘッダ
 * @param {Number} id ページのID（CSS用、今後廃止の予定）
 * @param {Object} navigate Push通知から遷移した場合の処理用
 * @param {Array} twilioCalls TwilioConnectionオブジェクトの配列
 * @param {Boolean} isTalkingPhone 通話中（着信）
 * @param {Boolean} isTalkingPC 通話中（発信）
 * @param {Object} startTime Twilio通話時間
 * @param {Object} selectedVehicle 選択中車両情報（Twilio通話中に映像表示に使用）
 * @param {Function} acceptCallAction Twilio取話
 * @param {Function} ignoreCallAction Twilio全無視
 * @param {Function} hangupCallAction Twilio終話
 * @param {Function} rejectCallAction Twilio拒否
 * @param {Number} pushAlertVehicleId Push通知された車両ID
 * @param {Function} resetNotificationAction Push通知された車両IDをリセット
 * @param {Array} operation 運行情報
 * @param {Array} runningDataDetail 運行情報詳細
 * @param {Boolean} autoHide true時スクロールでヘッダーが隠れる
 * @param {Boolean} hasGoBack 戻るボタン表示可否
 * @param {Function} goBack 戻るボタン押下時イベント
 */
export class Header extends React.Component<ComponentProps, State> {
  public static defaultProps = {
    twilioCalls: [],
    isTalkingPhone: false,
    isTalkingPC: false,
    pushAlertVehicleId: 0,
    startTime: null,
    operation: [],
    runningDataDetail: [],
    selectedVehicle: null,
    autoHide: false,
    hasGoBack: true,
    goBack: () => {
      /* empty */
    },
  };

  customStyles: any;

  isMount: any;

  constructor(props: ComponentProps) {
    super(props);

    this.acceptCall = this.acceptCall.bind(this);
    this.closeLoading = this.closeLoading.bind(this);
    this.installMedia = this.installMedia.bind(this);

    this.customStyles = {
      overlay: {
        background: 'rgba(0,0,0, .2)',
      },
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: '20%',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        width: '550px',
        height: '180px',
        background: styleConstants.background.light.primary,
        textAlign: 'center',
        transition: 'all 0.25s ease',
        overflow: 'hidden',
      },
    };

    this.state = {
      isHide: false,
    };

    this.isMount = false;

    this.onScrollHideHeader = this.onScrollHideHeader.bind(this);
  }

  componentDidMount() {
    const { autoHide } = this.props;

    this.installMedia();
    this.isMount = true;

    if (autoHide) {
      $('div#app > main').bind('scroll', this.onScrollHideHeader);
    }
  }

  installMedia() {
    const { whitelabel, updateWhiteLabelAction } = this.props;

    const agencyName = localStorage.getItem('agency');
    if (!agencyName) return;

    const now = moment().unix();
    if (
      whitelabel.backgroundImageExpiration > now &&
      whitelabel.companyLogoExpiration > now &&
      whitelabel.serviceLogoExpiration > now
    ) {
      return;
    }

    getLoginMediaListGet({
      agencyName,
      target: 'label',
    }).then((mediaRes) => {
      if (mediaRes.status !== 200) return;
      const mediaResouces = mediaRes.data?.media_resources;
      if (!mediaResouces) return;
      const newWhiteLabel = makeNewWhiteLabel(whitelabel, mediaResouces);

      updateWhiteLabelAction(newWhiteLabel);

      // NOTE: もしホワイトレーベルが設定されていたらその色に合わせてアプリアイコンカラーを変更
      const appleTouchIcons = document.querySelectorAll('link[rel="apple-touch-icon"]');
      const faviconIcons = document.querySelectorAll('link[rel="icon"]');
      const homeIcon = new Image();
      homeIcon.src = '/base_logo.png';
      homeIcon.onload = () => {
        // 読み込み完了時に発火する関数
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const SIZE_PXL = 57;
        canvas.width = SIZE_PXL;
        canvas.height = SIZE_PXL;
        // 白でぬりつぶす
        if (ctx) {
          console.log('update homeApp icon color');
          ctx.fillStyle = newWhiteLabel.headerColor;
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(homeIcon, 0, 0, SIZE_PXL, SIZE_PXL);
        }
        const imgBase64 = canvas.toDataURL('image/jpg');
        appleTouchIcons.forEach((icon) => {
          icon.setAttribute('href', imgBase64);
        });
        faviconIcons.forEach((icon) => {
          icon.setAttribute('href', imgBase64);
        });
      };
    });
  }

  componentDidUpdate() {
    const { pushAlertVehicleId, navigate, resetNotificationAction } = this.props;

    if (pushAlertVehicleId > 0) {
      resetNotificationAction();
      navigate('/pages/running-vehicles', {
        state: {
          vehicleID: pushAlertVehicleId,
        },
      });
    }
  }

  componentWillUnmount() {
    const { autoHide } = this.props;
    this.isMount = false;

    if (autoHide) {
      $('div#app > main').unbind('scroll', this.onScrollHideHeader);
    }
  }

  onScrollHideHeader() {
    const { isHide } = this.state;
    const scrollTop = $('div#app > main').scrollTop();
    if (scrollTop > scrollMargin && !isHide) {
      $('nav#sidebar').addClass('hideout');
      $('header#header').addClass('hideout');
      this.setState({ isHide: true });
    } else if (scrollTop < scrollMargin && isHide) {
      $('header#header').removeClass('hideout');
      $('nav#sidebar').removeClass('hideout');
      this.setState({ isHide: false });
    }
  }

  acceptCall(e: any) {
    const { acceptCallAction, twilioCalls } = this.props;

    acceptCallAction(e, twilioCalls);
  }

  // 全て切断する。
  closeLoading() {
    const { twilioCalls, ignoreCallAction } = this.props;
    const temp = twilioCalls.concat();
    temp.forEach((item: any) => {
      ignoreCallAction(item.conn);
    });
  }

  render() {
    let Caller: any[] = [];
    const {
      id,
      twilioCalls,
      isTalkingPhone,
      isTalkingPC,
      hangupCallAction,
      rejectCallAction,
      navigate,
      startTime,
      operation,
      runningDataDetail,
      selectedVehicle,
      hasGoBack,
      goBack,
      whitelabel,
    } = this.props;

    Caller = twilioCalls.map((BusCall: any, index: any) => {
      const { busData, conn: item } = BusCall;

      return (
        <CallDialog
          isOpen
          isTalking={isTalkingPhone || isTalkingPC}
          startTime={startTime}
          index={index}
          key={busData.courseID}
          busData={busData}
          runningDataDetail={runningDataDetail}
          conn={item}
        >
          <CallDialogPanel
            busData={busData}
            conn={item}
            hangupCall={hangupCallAction}
            rejectCall={rejectCallAction}
            accept={this.acceptCall}
            isTalking={isTalkingPhone || isTalkingPC}
            navigate={navigate}
            selectedVehicle={selectedVehicle}
          />
        </CallDialog>
      );
    });
    this.customStyles.content.height = `${twilioCalls.length * 155 + 145}px`;

    const goBackButton = hasGoBack ? (
      <Div_BackButton role="navigation" className="icon-back" onClick={goBack} onKeyDown={goBack}>
        <a></a>
      </Div_BackButton>
    ) : (
      ''
    );

    return (
      <>
        <header id="header" style={{ backgroundColor: whitelabel.headerColor }}>
          {goBackButton}
          <HeaderClock pollInterval={1000} />
          <div
            className="logo"
            style={{ background: `url(${whitelabel.companyLogoUrl}) center no-repeat` }}
          ></div>
          <LiveViewSession operation={operation}>{Caller}</LiveViewSession>
        </header>
        <aside id="drawer" className="caller-form" style={{ width: 0 }}>
          <section id={id ? String(id) : 'common-editor'} className="container-fluid"></section>
        </aside>
      </>
    );
  }
}

interface DispatchToProps {
  acceptCallAction: (conn: any, twilioCalls: any) => any;
  ignoreCallAction: (conn: any) => any;
  hangupCallAction: (conn: any) => any;
  rejectCallAction: (conn: any) => any;
  resetNotificationAction: () => any;
  updateWhiteLabelAction: (whitelabel: WhiteLabelReducerState) => void;
}
function mapDispatchToProps(dispatch: any): DispatchToProps {
  return bindActionCreators(
    {
      acceptCallAction: acceptCall,
      ignoreCallAction: ignoreCall,
      hangupCallAction: hangupCall,
      rejectCallAction: rejectCall,
      resetNotificationAction: resetNotification,
      updateWhiteLabelAction: updateWhiteLabel,
    },
    dispatch,
  );
}
interface StateToProps {
  twilioCalls: any[];
  isTalkingPhone: boolean;
  isTalkingPC: boolean;
  startTime: any;
  pushAlertVehicleId: number;
  operation: any[];
  runningDataDetail: any[];
  selectedVehicle: any;
  whitelabel: WhiteLabelReducerState;
}
function mapStateToProps(state: any): StateToProps {
  return {
    twilioCalls: state.twilioReducer.twilioCalls,
    isTalkingPhone: state.twilioReducer.isTalkingPhone,
    isTalkingPC: state.twilioReducer.isTalkingPC,
    startTime: state.twilioReducer.startTime,
    // operation: state.twilioReducer.operation,
    pushAlertVehicleId: state.notificationReducer.alertVehicleId,
    operation: state.runningReducer.operation,
    runningDataDetail: state.runningReducer.runningDataDetail,
    selectedVehicle: state.runningReducer.selectedVehicle,
    whitelabel: state.whiteLabelReducer,
  };
}
export default connect<StateToProps, DispatchToProps, Props>(
  mapStateToProps,
  mapDispatchToProps,
)(Header);

const Div_BackButton = styled.div`
  background-image: url(../../img/common/icon-back.svg);
  width: 18px;
  left: 25px;

  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 30px;
  position: absolute;
  top: 15px;
  cursor: pointer;
  display: block;
`;
