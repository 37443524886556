import * as React from 'react';
import {
  ALERT_SOUND_VOLUME,
  TOAST_OPTION,
  VIDEO_SOUND_KEY,
  VIDEO_SOUND_MUTE_KEY,
} from '../../common/constants';
import sendLog from '../../common/utils/sendLog';
import lang from '../../lang';
import ErrorCamera from './ErrorCamera';
import {
  Div_CameraContainer,
  Div_LoadingBox,
  FullScreenButton,
  LiveViewContainer,
  LoaderInner,
  VideoCaption,
} from './index.styles';
import { checkStatsItem } from './functions';
import lostConnectionToast from '../../common/utils/lostConnectionToast/lostConnectionToast';

export const TOAST_MSEC = 4000;
const RETRY_COUNT_MAX = 3;
const RETRY_INTERVAL_MSEC = 3000;
const LONG_RETRY_INTERVAL_MSEC = 60000;
const DATA_TYPE_VIDEO = 'video';
const DATA_TYPE_AUDIO = 'audio';

interface Props {
  cameraID: string;
  session: any;
  visible: boolean;
  cameraDisplayName: string;
  cameraIndexStr: string;
  isTalkingPhone: boolean;
  isTalkingPC: boolean;
  isMultipleLayout: boolean;
  vehicleName: string;

  isVehicleActive?: any;
  isFcBus: boolean;

  useTopPage?: boolean;
}
interface State {
  stream: any;
  retryCount: number;

  status?: string;
}
export default class LiveView extends React.Component<Props, State> {
  public static defaultProps = {
    cameraID: null,
    session: null,
    isTalkingPhone: false,
    isTalkingPC: false,
    isMultipleLayout: true,
    useTopPage: false,
    vehicleName: '',
  };

  isTalkingPC: any;

  isTalkingPhone: any;

  retryTimer: any;

  volume: any;

  static isSafariWebRTC() {
    return navigator.mediaDevices && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  constructor(props: any) {
    super(props);

    this.createStream = this.createStream.bind(this);
    this.onFullScreenClick = this.onFullScreenClick.bind(this);
    this.setPlay = this.setPlay.bind(this);
    this.retryCreateStream = this.retryCreateStream.bind(this);

    this.state = {
      stream: null,
      retryCount: 0,
    };
    this.isTalkingPhone = false;
    this.isTalkingPC = false;
    this.retryTimer = null;
  }

  componentDidMount() {
    const { visible } = this.props;
    if (visible) {
      this.processStream();
    }
  }

  componentDidUpdate(prevProps: any) {
    const { visible, session, isTalkingPhone, isTalkingPC } = this.props;
    if (visible && !prevProps.visible) {
      this.processStream();
    }
    if (session && !prevProps.session) {
      this.processStream();
    }
    if (isTalkingPhone !== prevProps.isTalkingPhone) {
      this.processStream(true);
    }
    if (isTalkingPC !== prevProps.isTalkingPC) {
      this.processStream(true);
    }
    if (prevProps.visible && !visible) {
      this.stopStream();
    }
  }

  componentWillUnmount() {
    const streamStatus = window.Flashphoner.constants.STREAM_STATUS;
    const { stream } = this.state;
    const { cameraID, cameraIndexStr, session } = this.props;
    if (!stream) {
      return;
    }
    stream.on(streamStatus.PLAYING, () => {
      stream.stop();
    });
    // stream STOPPED and FAILED callbacks を削除
    stream.on(streamStatus.STOPPED, () => {
      /* empty */
    });
    stream.on(streamStatus.FAILED, () => {
      /* empty */
    });
    stream.on(streamStatus.NOT_ENOUGH_BANDWIDTH, () => {
      /* empty */
    });
    const videoDoc = document.getElementById(cameraID + cameraIndexStr);
    if (videoDoc) {
      const videoElem = videoDoc.children[0];
      if (videoElem) {
        videoElem.removeEventListener('playing', this.setPlay);
      }
    }

    if (session && !session.isDisconnected && stream && stream.status() !== streamStatus.PENDING) {
      stream.stop();
    }

    if (this.retryTimer) {
      clearTimeout(this.retryTimer);
    }
  }

  onFullScreenClick() {
    const { stream } = this.state;
    if (stream) {
      stream.fullScreen();
    }
  }

  setPlay() {
    this.setState({ status: 'playing' });
  }

  retryCreateStream() {
    const sessionStatus = window.Flashphoner.constants.SESSION_STATUS;
    const { retryCount } = this.state;
    const { session, cameraDisplayName } = this.props;

    this.setState({
      status: 'loading',
      retryCount: retryCount + 1,
    });

    if (session) {
      const sessionState = session.status();
      console.info('session state in retry', sessionState + cameraDisplayName);
      if (sessionState === sessionStatus.ESTABLISHED) {
        this.createStream(session, this.props);
      } else {
        console.info('session is not established in retry.', sessionState);
      }
    } else {
      console.info('session does not exist.');
    }
  }

  stopStream() {
    const streamStatus = window.Flashphoner.constants.STREAM_STATUS;
    const { stream } = this.state;

    if (stream && stream.status() === streamStatus.PLAYING) {
      const _this = this;
      // streamが生成されていた場合、streamをストップしSTOPPEDを待ってStreamを改めて開始する
      stream.on(streamStatus.STOPPED, () => {
        _this.setState({ stream: null });
      });
      stream.on(streamStatus.FAILED, () => {
        /* empty */
      });

      stream.stop();
      this.setState({
        status: 'stopped',
      });
    }
  }

  processStream(changeMute = false) {
    const streamStatus = window.Flashphoner.constants.STREAM_STATUS;
    const { visible, cameraID, session, isTalkingPhone, isTalkingPC } = this.props;
    const { stream } = this.state;
    if (cameraID === null || cameraID === '' || cameraID === 'none' || !session) {
      return;
    }

    if (stream) {
      if (this.isTalkingPhone !== isTalkingPhone) {
        this.isTalkingPhone = isTalkingPhone;
        if (stream.status() === streamStatus.PLAYING) {
          if (isTalkingPhone) {
            this.volume = stream.getVolume();
            stream.setVolume(0);
          } else if (!isTalkingPhone && this.volume) {
            stream.setVolume(this.volume);
          }
        }
      }

      if (this.isTalkingPC !== isTalkingPC) {
        this.isTalkingPC = isTalkingPC;
        if (stream.status() === streamStatus.PLAYING) {
          if (isTalkingPC) {
            this.volume = stream.getVolume();
            if (this.volume <= 0) {
              stream.setVolume(100);
            }
          } else {
            stream.setVolume(this.volume);
          }
        }
      }

      if (changeMute) {
        return;
      }

      if (stream.status() === streamStatus.FAILED) {
        return;
      }

      if (stream.status() === streamStatus.PLAYING) {
        const _this = this;
        // streamが生成されていた場合、streamをストップしSTOPPEDを待ってStreamを改めて開始する
        stream.on(streamStatus.STOPPED, () => {
          _this.setState({ stream: null });
          if (session && visible) {
            _this.createStream(session, this.props);
          }
        });
        stream.on(streamStatus.FAILED, () => {
          /* empty */
        });

        stream.stop();
        this.setState({
          status: 'stopped',
        });
      }
      return;
    }

    this.createStream(session, this.props);
  }

  createStream(session: any, props: any) {
    const streamStatus = window.Flashphoner.constants.STREAM_STATUS;
    const { retryCount } = this.state;
    const { cameraID, cameraIndexStr, isTalkingPhone, vehicleName, cameraDisplayName } = props;
    if (!session) {
      return;
    }

    const videoPlaceholder = document.getElementById(cameraID + cameraIndexStr);

    if ((window as any).Flashphoner.getMediaProviders()[0] === 'WSPlayer') {
      (window as any).Flashphoner.playFirstSound();
    } else if (
      (LiveView.isSafariWebRTC() &&
        (window as any).Flashphoner.getMediaProviders()[0] === 'WebRTC') ||
      (window as any).Flashphoner.getMediaProviders()[0] === 'MSE'
    ) {
      (window as any).Flashphoner.playFirstVideo(videoPlaceholder);
    }

    const accessToken = window.sessionStorage.getItem('at');

    const options = {
      name: cameraID,
      display: videoPlaceholder,
      flashShowFullScreenButton: true,
      custom: { token: accessToken },
    };

    const _this = this;
    const stream = session
      .createStream(options)
      .on(streamStatus.PLAYING, (newStream: any) => {
        const vol = newStream.getVolume();
        if (vol >= 0 && !isTalkingPhone) {
          checkStatsItem(DATA_TYPE_AUDIO, newStream, vehicleName, cameraDisplayName);
        }
        checkStatsItem(DATA_TYPE_VIDEO, newStream, vehicleName, cameraDisplayName);

        // 配列の分割代入
        const videoElem = document.getElementById(cameraID + cameraIndexStr);
        if (!videoElem) {
          return;
        }
        [(_this as any).videoElem] = Array.from(videoElem.children);

        if (!(_this as any).videoElem) {
          return;
        }
        (_this as any).videoElem.addEventListener('playing', this.setPlay, false);
        // iOS Safariの場合、自動再生されないため、明示的にplay()を呼ぶ
        if (LiveView.isSafariWebRTC()) {
          (_this as any).videoElem.play();
        }
        // "play"を検知するリスナを張った時点でplayingを過ぎている場合があるため、この瞬間の再生状態を見て
        // 再生中であればsetStateする
        if ((_this as any).videoElem.readyState >= 2) {
          _this.setState({
            status: 'playing',
            retryCount: 0,
          });
        }

        _this.volume = _this.state.stream.getVolume();
        if (_this.volume > -1) {
          let volume =
            Number(window.localStorage.getItem(VIDEO_SOUND_KEY) || ALERT_SOUND_VOLUME) * 100;
          if (window.localStorage.getItem(VIDEO_SOUND_MUTE_KEY) === 'true') {
            volume = 0;
          }
          _this.state.stream.setVolume(volume);

          if (isTalkingPhone) {
            _this.state.stream.setVolume(0);
            _this.isTalkingPhone = isTalkingPhone;
          }
        }
      })
      .on(streamStatus.STOPPED, (createdStream: any) => {
        console.info('streamStatus.STOPPED', createdStream);
        _this.setState({ stream: null, status: 'stopped', retryCount: 0 });
      })
      .on(streamStatus.FAILED, (createdStream: any) => {
        if (_this.state.retryCount === 0) {
          lostConnectionToast(
            `${vehicleName} ${cameraDisplayName}: ${lang.get('in_operation_vehicle.camera.disabled')} ${createdStream.getInfo()}`,
            TOAST_OPTION,
          );
        }

        console.info('streamStatus.FAILED,', createdStream);
        if (_this.props.isVehicleActive) {
          sendLog('video stream failed', 'ERROR', undefined);
        }
        if (retryCount < RETRY_COUNT_MAX) {
          console.info('retry create stream: ', retryCount);
          _this.retryTimer = setTimeout(_this.retryCreateStream, RETRY_INTERVAL_MSEC * retryCount);
        } else {
          _this.retryTimer = setTimeout(_this.retryCreateStream, LONG_RETRY_INTERVAL_MSEC);
        }
      })
      .on(streamStatus.NOT_ENOUGH_BANDWIDTH, (createdStream: any) => {
        lostConnectionToast(
          `${vehicleName} ${cameraDisplayName}: ${lang.get('in_operation_vehicle.camera.disabled')} ${createdStream.getInfo()}`,
          TOAST_OPTION,
        );
        console.info(
          'streamStatus.NOT_ENOUGH_BANDWIDTH',
          `Not enough bandwidth, consider using lower video resolution or bitrate. Bandwidth ${Math.round(
            createdStream.getNetworkBandwidth() / 1000,
          )} bitrate ${Math.round(createdStream.getRemoteBitrate() / 1000)}`,
        );
      });

    stream.play();
    this.setState({ stream, status: 'loading' });
  }

  render() {
    const { status } = this.state;
    const { cameraID, cameraIndexStr, isMultipleLayout, cameraDisplayName, useTopPage, isFcBus } =
      this.props;

    const videoVisibility = status === 'playing' ? '' : 'none';
    const showLoadingBox = status !== 'playing' && status !== 'error';
    const showErrorCamera = status === 'error';

    if (cameraID === '' || cameraID === 'none') {
      // [カメラがありません]の画像を表示する
      return (
        <LiveViewContainer className="live-view" $useTopPage={useTopPage}>
          <ErrorCamera isFcBus={isFcBus} show>
            {lang.get('in_operation_vehicle.liveview.no_camera')}
          </ErrorCamera>
        </LiveViewContainer>
      );
    }

    const fullScreenButton = isMultipleLayout ? (
      <FullScreenButton
        className="fullscreen"
        onClick={this.onFullScreenClick}
        onKeyDown={this.onFullScreenClick}
        role="button"
        tabIndex={0}
        style={{ zIndex: 3 }}
      >
        <i className="material-icons white">fullscreen</i>
      </FullScreenButton>
    ) : (
      ''
    );

    const videoCaption = (
      <VideoCaption className="video-caption">
        <p>{cameraDisplayName}</p>
        {fullScreenButton}
      </VideoCaption>
    );

    return (
      <LiveViewContainer className="live-view">
        <Div_CameraContainer
          $useTopPage={useTopPage}
          className="camera-container"
          style={{ display: videoVisibility }}
        >
          <div className="display" id={cameraID + cameraIndexStr} />
          {videoCaption}
        </Div_CameraContainer>
        <Div_LoadingBox className="loading-box" $showLoadingBox={showLoadingBox}>
          <LoaderInner className="loader-inner ball-pulse">
            <div></div>
            <div></div>
            <div></div>
          </LoaderInner>
        </Div_LoadingBox>
        <ErrorCamera isFcBus={isFcBus} show={showErrorCamera}>
          {lang.get('in_operation_vehicle.message.no_video')}
        </ErrorCamera>
      </LiveViewContainer>
    );
  }
}
