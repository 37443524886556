// twilioの通知周りを管理する。
import moment from 'moment';
import * as types from '../../actions/twilio/actionTypes';
import * as runningTypes from '../../actions/running/actionTypes';
import lang from '../../lang';
import { callPositionList } from '../../pages/running-vehicles/RunningVehicleDetail/VehicleControls/inOutCallerButton/functions';
import { SIP_CALL, TOAST_OPTION, VEHICLE_PREFIX } from '../../common/constants';
import lostConnectionToast from '../../common/utils/lostConnectionToast/lostConnectionToast';

export interface TwillioReducerState {
  twilioCalls: any[];
  callBusNames: any[];
  twilioReady: boolean;
  talkingConnection: any;
  isTalkingPhone: boolean;
  isTalkingPC: boolean;
  elapsedTimeSeconds: number;
  operation: any[];
  runningDataDetail: any[];
}
const defaultState: TwillioReducerState = {
  twilioCalls: [],
  callBusNames: [],
  twilioReady: false,
  talkingConnection: undefined,
  isTalkingPhone: false,
  isTalkingPC: false,
  elapsedTimeSeconds: 0,
  operation: [],
  runningDataDetail: [],
};

const STATUS_CHECK_INTERVAL = 1000;
const DISCONNECT_CALL_TIME = 3000;

/**
 * @description 指定したConnectionを削除する
 * @param {Array} twilioCalls Twilio Connectioの配列
 * @param {Object} conn 削除するConnection
 * @param {Boolean} forceFlg ConnectionがCloseしていないくても削除する
 * @return {Array} 削除した配列
 */
function deleteConnectionObject(twilioCalls: any, conn: any, forceFlg: any) {
  const tempCalls = twilioCalls.concat();
  if (conn.status() === 'closed' || forceFlg) {
    twilioCalls.some((item: any, index: any) => {
      if (item.conn.parameters.CallSid === conn.parameters.CallSid) {
        tempCalls.splice(index, 1);
        clearInterval(item.statusCheckTimerId);
        return true;
      }
      return false;
    });
  }
  return tempCalls;
}

/**
 * @description Twilio関係を管理するReducer
 * @param {Object} [state=defaultState] reducer state
 * @param {Object} action redux actoon
 */
export default (state = defaultState, action: any) => {
  switch (action.type) {
    case types.INIT_TWILIO:
      return Object.assign({}, state, {
        twilioReady: action.payload.twilioReady,
      });
    case types.RECEIVE_INCOMING_CALL: {
      const {
        twilioCalls,
        // callBusNames,
        operation,
        runningDataDetail,
      } = state;
      const { talkingConnection } = action.payload;
      const tempCalls = twilioCalls.concat();
      const from = talkingConnection.parameters.From.replace('client:', '');

      let vehicleID = 0;
      let position = '';
      if (from.includes(SIP_CALL)) {
        const matchVehicleNumber = from.match(new RegExp(`${VEHICLE_PREFIX}(\\d+)`));
        const extractedValue = matchVehicleNumber && matchVehicleNumber[1];
        vehicleID = parseInt(extractedValue, 10);
        if (from.includes(callPositionList.inside)) {
          position = lang.get('running-vehicle.caller-button_inside_car_call');
        } else if (from.includes(callPositionList.outside)) {
          position = lang.get('running-vehicle.caller-button_outside_car_call');
        } else if (from.includes(callPositionList.announcement)) {
          position = lang.get('running-vehicle.caller-button_announcement_car_call');
        }
      } else {
        vehicleID = parseInt(from, 10);
      }

      const foundOperation = operation.find((item: any) => item.vehicle_id === vehicleID);
      if (!foundOperation) return state;

      const foundRunningDataDetail = runningDataDetail.find(
        (item: any) => item.vehicle.vehicle_id === vehicleID,
      );
      if (foundRunningDataDetail) {
        console.info(foundRunningDataDetail);
      }

      const busCall = {
        conn: talkingConnection,
        busData: {
          vehicleName: foundRunningDataDetail.vehicle.vehicle_name,
          vehicleNumber: position ? position : foundRunningDataDetail.vehicle.vehicle_number,
          vehicleID: foundOperation.vehicle_id,
        },
        statusCheckTimerId: setInterval(() => {
          deleteConnectionObject(twilioCalls, talkingConnection, false);
        }, STATUS_CHECK_INTERVAL),
      };
      tempCalls.push(busCall);
      return Object.assign({}, state, {
        twilioCalls: tempCalls,
      });
    }
    case types.DISCONNECT_CALL:
    case types.CANCEL_CALL: {
      const { talkingConnection } = action.payload;
      const { twilioCalls } = state;

      const nowTime = Date.now();
      const startTime = twilioCalls[0]?.busData?.startTime ?? 0;
      const timeDiff = nowTime - startTime;
      if (startTime && timeDiff < DISCONNECT_CALL_TIME) {
        lostConnectionToast(lang.get('running-vehicle.call-disconnect'), TOAST_OPTION);
      }
      const tempCalls = deleteConnectionObject(twilioCalls, talkingConnection, true);
      const isTalking: { [key: string]: any } = {};
      if (tempCalls.length <= 0) {
        isTalking.isTalkingPC = false;
        isTalking.isTalkingPhone = false;
      }
      return Object.assign({}, state, {
        talkingConnection: null,
        twilioCalls: tempCalls,
        ...isTalking,
      });
    }
    case types.HANGUP_CALL: {
      const { talkingConnection } = action.payload;
      const { twilioCalls } = state;
      const tempCalls = deleteConnectionObject(twilioCalls, talkingConnection, true);
      const isTalking: { [key: string]: any } = {};
      if (tempCalls.length <= 0) {
        isTalking.isTalkingPC = false;
        isTalking.isTalkingPhone = false;
      }
      return Object.assign({}, state, {
        talkingConnection: null,
        twilioCalls: tempCalls,
        ...isTalking,
      });
    }
    case types.ACCEPT_CALL: {
      const { talkingConnection } = action.payload;
      const { twilioCalls } = state;
      const acceptedCall = twilioCalls.find((item) => {
        if (item.conn.parameters.CallSid === talkingConnection.parameters.CallSid) {
          clearInterval(item.statusCheckTimerId);
          return true;
        }
        return false;
      });
      // let copiedCalls = twilioCalls.concat();
      twilioCalls.forEach((item) => {
        if (item.conn.parameters.CallSid !== talkingConnection.parameters.CallSid) {
          deleteConnectionObject(twilioCalls, item.conn, true);
        }
      });

      return Object.assign({}, state, {
        talkingConnection,
        twilioCalls: [acceptedCall],
        isTalkingPC: action.payload.isTalkingPC,
        isTalkingPhone: action.payload.isTalkingPhone,
        startTime: moment(),
      });
    }
    case types.MAKE_CALL_TO_VEHICLE: {
      const { twilioCalls } = state;
      const { talkingConnection, vehicle, course } = action.payload;
      const copiedCalls = twilioCalls.concat();
      const busCall = {
        conn: talkingConnection,
        busData: {
          courseID: course.course_id,
          vehicleName: vehicle.vehicle_name,
          vehicleNumber: vehicle.vehicle_number,
          vehicleID: vehicle.vehicle_id,
          courseName: 'busName.courseName',
          startTime: Date.now(),
        },
        statusCheckTimerId: setInterval(() => {
          deleteConnectionObject(twilioCalls, talkingConnection, false);
        }, STATUS_CHECK_INTERVAL),
      };
      copiedCalls.push(busCall);
      return Object.assign({}, state, {
        talkingConnection,
        isTalkingPC: action.payload.isTalkingPC,
        isTalkingPhone: action.payload.isTalkingPhone,
        twilioCalls: copiedCalls,
        startTime: moment(),
      });
    }
    case types.IGNORE_CALL: {
      const { talkingConnection } = action.payload;
      const { twilioCalls } = state;
      const tempCalls = deleteConnectionObject(twilioCalls, talkingConnection, true);

      return Object.assign({}, state, {
        talkingConnection,
        twilioCalls: tempCalls,
        isTalkingPC: action.payload.isTalkingPC,
        isTalkingPhone: action.payload.isTalkingPhone,
      });
    }
    case types.REJECT_CALL: {
      const { talkingConnection } = action.payload;
      const { twilioCalls } = state;
      const tempCalls = deleteConnectionObject(twilioCalls, talkingConnection, true);

      return Object.assign({}, state, {
        talkingConnection: null,
        twilioCalls: tempCalls,
        isTalkingPC: action.payload.isTalkingPC,
        isTalkingPhone: action.payload.isTalkingPhone,
      });
    }
    case types.TWILIO_FETCH_VEHICLE_NAME: {
      const { busNames, operation } = action.payload;
      return Object.assign({}, state, {
        callBusNames: busNames,
        operation,
      });
    }
    case runningTypes.FETCH_RUNNING_DATA: {
      const { operation, runningDataDetail } = action.payload;
      const callBusNames = operation.map((item: any) => ({
        courseID: item.course_id,
        courseName: item.course_name,
        vehicleName: item.vehicle_name,
        vehicleID: item.vehicle_id,
      }));
      return Object.assign({}, state, {
        callBusNames,
        operation,
        runningDataDetail,
      });
    }
    default:
      return state;
  }
};
