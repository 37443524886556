import moment from 'moment';
// import momentTimezone from 'moment-timezone';
import React, { Component } from 'react';
import styled from 'styled-components';
import lang from '../../../lang';
import styleConstants from '../../../contents/css/constants/styleConstants';

require('moment/min/locales.min');

interface Props {
  pollInterval: number;
}
interface State {
  time: moment.Moment;
}
/**
 * @description 時計コンポーネント
 * @param {Number} pollInterval 時計の表示更新タイミング
 */
class HeaderClock extends Component<Props, State> {
  pollingTimer: any;

  constructor(props: any) {
    super(props);

    this.state = {
      time: moment(),
    };

    this.TimerHandler = this.TimerHandler.bind(this);
  }

  componentDidMount() {
    const { pollInterval } = this.props;
    this.TimerHandler();
    this.pollingTimer = setInterval(this.TimerHandler, pollInterval);
  }

  componentWillUnmount() {
    clearInterval(this.pollingTimer);
  }

  TimerHandler() {
    this.setState({
      time: moment(),
    });
  }

  render() {
    const { time } = this.state;

    if (!time) {
      return '';
    }

    const dateTimeDisplay =
      lang.get('moment.locale') === 'fi' ? (
        <>
          {time.format('D.M')}
          <span>{`（${time.format('ddd')}）`}</span>
          <span>{time.format('HH:mm:ss')}</span>
        </>
      ) : (
        <>
          {time.format('M')}
          <span>月</span>
          {time.format('D')}
          <span>日</span>
          <span>{`（${time.format('ddd')}）`}</span>
          <span>{time.format('HH:mm:ss')}</span>
        </>
      );

    return <Span_ClockContainer>{dateTimeDisplay}</Span_ClockContainer>;
  }
}

export default HeaderClock;

const Span_ClockContainer = styled.span`
  position: absolute;
  font-family: 'Roboto', 'Noto Sans JP', sans-serif;
  top: 8px;
  right: 20px;
  color: ${styleConstants.text.light.secondary};
  height: 44px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 18px;
  text-align: center;
  padding-top: 7px;
  letter-spacing: 0.48px;
`;
