import { getAPIClientAsync } from '../../../common/utils/aws';
import { CommonDataRes } from '../apiGetResponse';
import { VehicleCapabilities } from '../getRunningDataDetail';

export interface GetDemandRunningDataDetailReq {
  reservationKey?: string;
  vehicleId?: number;
  areaId?: string;
}

export interface GetDemandRunningDataDetailData extends CommonDataRes {
  reservation?: {
    reservation_key: string;
    from_stop_name: string;
    to_stop_name: string;
    departure_time: string;
    arrival_time: string;
    drive_departure_time: string;
    drive_arrival_time: string;
    path: Array<{
      stop_name: string;
      stop_id: number;
      departure_time: string;
      arrival_time: string;
      is_parking: boolean;
    }>;
  };
  employee?: {
    employee_id: number;
    employee_number: string;
    employee_first_name: string;
    employee_last_name: string;
  };
  vehicle: {
    vehicle_id: number;
    vehicle_name: string;
    vehicle_number: string;
    vehicle_maker: string;
    vehicle_type: number;
    vehicle_type_id?: number;
    capacity: number;
    wheelchair_accesible?: number;
    bikes_allowed?: number;
    vehicle_tel?: string;
    camera_layout?: string;
    maincamera_id?: string;
    cameras?: Array<{
      camera_id: string;
      camera_name: string;
      position: string;
      resolution: {
        width: number;
        height: number;
      };
    }>;
    api_version?: string;
    capabilities?: VehicleCapabilities;
  };
  area: {
    area_id: string;
    lines?: number[][][] | number[][];
  };
  check_status?: {
    before: boolean;
    after: boolean;
  };
}

export interface GetDemandRunningDataDetailRes {
  data?: GetDemandRunningDataDetailData;
  status?: number;
  code?: string;
  message?: string;
}

/**
 * @description 当日の運行情報詳細を取得する（デマンド用）
 * @param {String} reservationKey 予約ID
 */
export default async function getRunningDataDetail({
  reservationKey,
  vehicleId,
  areaId,
}: GetDemandRunningDataDetailReq): Promise<GetDemandRunningDataDetailRes> {
  const apigClient = await getAPIClientAsync();

  const params = !!reservationKey
    ? {
        reservation_key: reservationKey || null,
        vehicle_id: null,
        area_id: null,
      }
    : {
        reservation_key: null,
        vehicle_id: vehicleId || null,
        area_id: areaId || null,
      };

  const additionalParams = {
    headers: {},
    queryParams: {},
  };

  const body = {};

  return apigClient.demandGetRunningDataDetailGet(params, body, additionalParams);
}
