import moment from 'moment';
import React, { Component } from 'react';
import { Rnd } from 'react-rnd';
import lang from '../../../lang';
import LiveView from '../../../pages/in-operation-vehicle/liveview';
import styleConstants from '../../../contents/css/constants/styleConstants';

import $ from 'jquery';
import { FC_BUS } from '../../constants';

const offset = 270;
const headerAndControlHeight = 208;

interface Props {
  liveViewSession: any;
  runningDataDetail: any[];
  index: number;
  isOpen: boolean;
  isTalking: boolean;
  children: any;
  startTime: any;
  busData: {
    courseID: any;
    vehicleID: any;
  };
  conn: any;
}

interface State {
  width: number;
  height: number;
  x: number;
  y: number;
  videoHeight: number;
  callTime: string;
  initialOffsetY: number;
  timer?: any;
}

/**
 * @description Twilio着信ダイアログ
 * @param {Object} liveViewSession
 * @param {Array} runningDataDetail
 * @param {number} index
 * @param {boolean} isOpen
 * @param {boolean} isTalking
 * @param {Object} children
 * @param {Object} startTime
 * @param {Object} busData
 * @param {Object} conn
 */
class CallDialog extends Component<Props, State> {
  public static defaultProps = {
    isOpen: false,
    startTime: null,
    liveViewSession: null,
    runningDataDetail: [],
    busData: {
      courseID: null,
      vehicleID: null,
    },
  };

  constructor(props: any) {
    super(props);
    const { index } = props;
    this.state = {
      width: 240,
      height: 343,
      x: 10,
      y: 150,
      videoHeight: 0,
      callTime: lang.get('running-vehicle.caller-button_connect'),
      initialOffsetY: index * 340,
    };

    this.timerHandler = this.timerHandler.bind(this);
  }

  componentDidMount() {
    const { isTalking } = this.props;
    this.updateStyle();
    $(window).bind('resize', () => {
      this.updateStyle();
    });
    if (isTalking) {
      this.startTimer();
    }
  }

  componentDidUpdate(prevProps: any) {
    const { isOpen, isTalking } = this.props;
    const { isOpen: prevIsOpen, isTalking: prevIsTalking } = prevProps;
    if (isOpen && !prevIsOpen) {
      this.updateStyle();
    }
    if (isTalking && !prevIsTalking) {
      this.startTimer();
    }
  }

  componentWillUnmount() {
    const { timer } = this.state;
    $(window).unbind('resize');

    if (timer) {
      clearInterval(timer);
    }
  }

  startTimer() {
    const timer = setInterval(this.timerHandler, 1000);
    this.setState({
      timer,
    });
  }

  updateStyle() {
    const { clientWidth } = document.getElementById('header') || {};
    const videoHeight = (document.getElementsByClassName('call-dialog')[0] || [{}]).clientHeight;
    if (!clientWidth) {
      return;
    }
    this.setState({
      x: clientWidth - offset,
      videoHeight: videoHeight - headerAndControlHeight,
    });
  }

  timerHandler() {
    const { startTime } = this.props;
    const nowTime = moment();
    const elapsedTimeSeconds = nowTime.diff(startTime, 's');
    this.setState({
      callTime: moment().hour(0).minute(0).second(elapsedTimeSeconds).format('HH:mm:ss'),
    });
  }

  render() {
    const { width, height, x, y, videoHeight, callTime, initialOffsetY } = this.state;

    const { isOpen, children, isTalking, runningDataDetail, liveViewSession, index, busData } =
      this.props;

    const style = {
      display: isOpen ? 'block' : 'none',
      border: `1px solid ${styleConstants.border.light.border}`,
    };
    const videoStyle = {
      height: videoHeight,
    };

    let targetRunningDataDetail: any = null;
    let cameraID: any = null;
    let cameraName = lang.get('common.unknown');

    if (
      runningDataDetail &&
      runningDataDetail.some(
        (item: any) => item.course && item.course.course_id === busData.courseID,
      )
    ) {
      // Scheduleの場合
      targetRunningDataDetail = runningDataDetail.find(
        (item: any) => item.course && item.course.course_id === busData.courseID,
      );
    } else if (runningDataDetail && busData.vehicleID) {
      // Demandの場合、runningDataDetailにcourseが入っていないので、vehicle IDでマッチするrunningDetailを探す
      targetRunningDataDetail = runningDataDetail.find(
        (item: any) => item.vehicle.vehicle_id === busData.vehicleID,
      );
    }
    if (!targetRunningDataDetail) {
      return '';
    }
    const { cameras, vehicle_type_id } = targetRunningDataDetail.vehicle;
    cameraID = targetRunningDataDetail.vehicle.maincamera_id;
    const foundCamera = cameras.find((camera: any) => camera.camera_id === cameraID);
    cameraName = foundCamera ? foundCamera.camera_name : lang.get('common.unknown');

    let targetliveViewSession = null;

    if (liveViewSession) {
      targetliveViewSession = liveViewSession[busData.vehicleID];
    }

    const callVideoIndex = index + 100;
    const liveView =
      targetliveViewSession && cameraID ? (
        <LiveView
          isFcBus={vehicle_type_id === FC_BUS}
          cameraDisplayName={cameraName}
          cameraID={cameraID}
          vehicleName={targetRunningDataDetail?.vehicle?.vehicle_name}
          session={targetliveViewSession}
          visible
          cameraIndexStr={`000${callVideoIndex}`.slice(-3)}
          // indexValue={callVideoIndex}
          isTalkingPhone
          // className={`call-camera ${conn.status() === 'open' ? '' : 'hidden'}`}
          isMultipleLayout={false}
        />
      ) : (
        ''
      );

    return (
      <Rnd
        style={style}
        size={{ width, height }}
        position={{ x, y: y + initialOffsetY }}
        onDragStop={(e, d) => {
          this.setState({ x: d.x, y: d.y, initialOffsetY: 0 });
        }}
        onDrag={(e, d) => {
          this.setState({ x: d.x, y: d.y, initialOffsetY: 0 });
        }}
        onResize={(e, direction, ref, delta, position) => {
          this.setState({
            width: parseInt(ref.style.width),
            height: parseInt(ref.style.height),
            ...position,
            videoHeight:
              document.getElementsByClassName('call-dialog')[0].clientHeight -
              headerAndControlHeight,
            initialOffsetY: 0,
          });
        }}
        className="call-dialog"
      >
        <div className={`header ${isTalking ? 'elapsed-time' : 'incoming'}`}>
          {isTalking ? callTime : lang.get('running-vehicle.caller-button_receive_call')}
        </div>
        <div className="video-area" style={videoStyle}>
          {liveView}
        </div>
        <div className="control-area">{children}</div>
      </Rnd>
    );
  }
}

export default CallDialog;
// CallDialog.propTypes = {
//   liveViewSession: PropTypes.object,
//   runningDataDetail: PropTypes.array,
//   index: PropTypes.number.isRequired,
//   isOpen: PropTypes.bool,
//   isTalking: PropTypes.bool.isRequired,
//   children: PropTypes.object.isRequired,
//   startTime: PropTypes.object,
//   busData: PropTypes.object,
//   conn: PropTypes.object.isRequired,
// };
// CallDialog.defaultProps = {
//   isOpen: false,
//   startTime: null,
//   liveViewSession: null,
//   runningDataDetail: null,
//   busData: null,
// };
